.SinglePost-wrapper {
  margin: 0 auto 30px;

  @media screen and (min-width: 800px) {
    max-width: 1100px;
  }
}

.SinglePost {
  padding: 90px 0px 5px;
  width: 80%;
  margin: 0 auto;

  .Title {
    font-weight: 700;
    line-height: 1.2;
    color: #565656;
    font-size: 25px;
    margin: 0 auto;

    @media screen and (min-width: 800px) {
      font-size: 40px;
    }
  }

  .single-post--image {
    height: 400px;
    margin-top: 20px;

    img {
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: cover;
    }
  }

  .Post-body {
    margin: 30px auto 0px;
    text-align: left;

    p {
      color: rgba(20, 20, 20, 0.7);
      font-size: 18px;
      line-height: 2;
      margin-bottom: 20px;
    }
  }

  .blogImage {
    width: 80%;
    margin: 40px 0px 20px;
  }
}

.skeleton {
  margin: 0 auto;
  width: 60%;

  h1 {
    margin-top: 80px;
    text-align: center;
  }

  p {
    margin-top: 70px;
  }
}
