.root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main__section {
  flex: 1;
}

.container {
  margin: 70px auto;
  max-width: 1100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container form {
  width: 80%;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.25);

  @media screen and (min-width: 800px) {
    width: 55%;
  }
}

.form__heading {
  font-weight: bold;
  line-height: 34px;
  color: #ef0633;
  padding: 30px 0px;
  border-bottom: 0.5px solid rgba(136, 136, 136, 0.5);
  text-align: center;
  font-size: 25px;

  @media screen and (min-width: 800px) {
    font-size: 40px;
  }
}

.form__control {
  margin: 0 10%;
}

.form__control label {
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  span {
    font-weight: 600;
    font-size: 15px;
    line-height: 23px;
    color: #565656;
  }
}

.form__control input {
  width: 100%;
  margin: 13px 0 0;
  border: 0.5px solid #888888;
  border-radius: 6px;
  height: 50px;
  padding: 15px 12px;
  font-size: 1rem;

  &::placeholder {
    color: rgba(86, 86, 86, 0.5);
    font-size: 15px;
  }
}

.form__control button {
  padding: 15px 50px;
  background-color: #ef0633;
  border-radius: 6px;
  color: white;
  cursor: pointer;
  border: none;
  font-size: 17px;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 60px;

  &:disabled {
    opacity: 0.7;
  }
}

.form__spinner {
  height: 20px;
  width: 20px;
  margin-left: 10px;
  animation: rotate-s-loader 1s linear infinite;
}

@keyframes rotate-s-loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.form__confirmation {
  color: #ef0633;
  margin: 50px 10%;
}

.confirmation__container {
  margin: 50px 10%;
}

.confirmation__text {
  color: #565656;
  font-weight: 600;
}

.confirmation__container a {
  padding: 15px 50px;
  background-color: #ef0633;
  border-radius: 6px;
  color: white;
  cursor: pointer;
  border: none;
  font-size: 17px;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin-top: 30px;
}
