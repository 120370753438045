.header {
  // justify-content: space-evenly;
  align-items: center;
  padding: 30px;
  background: #c4c4c4;
  font-size: 16px;
  color: #4a4a4a;
  font-weight: bold;
  height: 58px;

  @media screen and (min-width: 800px) {
    display: flex;
  }
}
.container {
  padding: 20px 30px;
}

.inner__container {
  display: flex;
  align-items: center;
}

.header__details,
.column__details {
  flex-basis: 50%;
  max-width: 50%;
}

.header__plan,
.column__plan {
  // flex-basis: 20%;
  // max-width: 20%;
  flex: 1;
  text-align: center;
}

.header__quantity,
.column__quantity {
  // flex-basis: 15%;
  // max-width: 15%;
  flex: 1;

  text-align: center;
}

.header__price,
.column__price {
  // flex-basis: 15%;
  // max-width: 15%;
  flex: 1;

  text-align: center;
}

.column__details {
  display: flex;
  // justify-content: center;
  align-items: center;

  &__img {
    height: 104px;
    width: 104px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__text {
    padding-left: 15px;
    padding-right: 15px;

    p:first-of-type {
      font-weight: 600;
      font-size: 18px;
      color: #565656;
      line-height: calc(23 / 18);
    }

    p:last-of-type {
      font-weight: 600;
      font-size: 12px;
      color: #888888;
      line-height: calc(23 / 10);
    }
  }
}

.column__price {
  p {
    font-weight: 600;
    font-size: 18px;
    color: #ef0633;
  }
}

.column__quantity,
.column__plan {
  p {
    color: #888888;
    font-size: 18px;
    font-weight: 600;
  }
}
